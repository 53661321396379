.pageContent {
    padding: 2em 2em 4em 2em;
    border: 1px solid #e5e5e5;
    background-color: #f9f9f9;
    webkit-box-shadow: 0 4px 4px rgba(243,243,243,0.56);
    box-shadow: 0 4px 4px rgba(243,243,243,0.56);
}

.logo {
    line-height: 50px;
    width: 135px;
    margin-top: -11px;
}

.cadre{
    background: #FFF;
    border: 1px solid #eee;
    padding: 2em 2em 4em 2em;
}

.navbar-default {
    background: #fff !important;
}

.title {
    padding-bottom: 5px;
    display: inline-block;
    border-bottom: 2px solid #f0ad4e;
}

.headline-title {
    color: #404852;
    font-weight: bold;
    border-bottom: 1px solid #E25041;
    display: inline-block;
}

.mb-20{
    margin-bottom: 20px;
}

.panel-default > .panel-heading.panel-heading-error {
    background: #d9534f; color: #fff;
}

.panel-default > .panel-heading.panel-heading-success {
    background: #5cb85c; color: #fff;
}

.dl-horizontal.dl-horizontal-align-left dt {
    text-align: left;
}

/********* CALENDAR ****************/
.calendar-day {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    height: 80px;
}
.table.calendar-table {
    margin: 0 auto;
    width: 100%;
}
.selected {
    background-color: #eee;
}
.outside .date {
    color: #ccc;
}
.timetitle {
    white-space: nowrap;
    text-align: right;
}
.event.closed {
    color: #fff;
    border-color: #ddd;
    background-image: linear-gradient(to bottom, #bbb 0px, #ccc 100%);
}
.event.current {
    color: #3c763d;
    border-color: #b2dba1;
    background-image: linear-gradient(to bottom, #dff0d8 0px, #c8e5bc 100%);
}
.event.future {
    color: #31708f;
    border-color: #9acfea;
    background-image: linear-gradient(to bottom, #d9edf7 0px, #b9def0 100%);
}
.event {
    border-top: 1px solid;
    border-bottom: 1px solid;
    background-repeat: repeat-x;
    border-width: 1px;
    font-size: .75em;
    padding: 0 .75em;
    line-height: 2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 1px;
}
.event.begin {
    border-left: 1px solid #b2dba1;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.event.end {
    border-right: 1px solid #b2dba1;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.event.all-day.begin {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.event.all-day.end {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.event.clear {
    background: none;
    border: 1px solid transparent;
}
.table-tight > thead > tr > th,
.table-tight > tbody > tr > th,
.table-tight > tfoot > tr > th,
.table-tight > thead > tr > td,
.table-tight > tbody > tr > td,
.table-tight > tfoot > tr > td {
    padding-left: 0;
    padding-right: 0;
}
.table-tight-vert > thead > tr > th,
.table-tight-vert > tbody > tr > th,
.table-tight-vert > tfoot > tr > th,
.table-tight-vert > thead > tr > td,
.table-tight-vert > tbody > tr > td,
.table-tight-vert > tfoot > tr > td {
    padding-top: 0;
    padding-bottom: 0;
}
[data-prolongation] {
    cursor: pointer;
}